import React from 'react'
import Gross from './Gross'
import AmericanMovers from './AmericanMovers'
import './Shippers.css'

function Shippers() {
  return (
      <div className=" truck-main-div11122">
          <div className="container-fluid truck-div1">
              <div className="w-100 trucks-div-11">
                  <div className="row">
                      <div className="col-lg-6 col-12 d-flex justify-content-center">
                          <div>
                              <div>
                                  <h1 className="truck-dispatch-text1">For Shippers <br /> & Brokers
</h1>
                              </div>
                              <div className="truck-dispatch-divtext1">
                                  <div className="d-flex align-items-center">
                                      <div className="truck-arrow-svg1">
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                          >
                                              <path
                                                  d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                                                  fill="white"
                                              />
                                          </svg>
                                      </div>
                                      <p className="truck-arrow-para1">
                                          Thousands of trucks to move your loads
                                      </p>
                                  </div>

                                  <div className="d-flex align-items-center mt-4">
                                      <div className="truck-arrow-svg1">
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                          >
                                              <path
                                                  d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                                                  fill="white"
                                              />
                                          </svg>
                                      </div>
                                      <p className="truck-arrow-para1">
                                          24/7 updates and load tracking
                                      </p>
                                  </div>

                                  <div className="d-flex align-items-center mt-4">
                                      <div className="truck-arrow-svg1">
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                          >
                                              <path
                                                  d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                                                  fill="white"
                                              />
                                          </svg>
                                      </div>
                                      <p className="truck-arrow-para1">No double brokering and hidden fees</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-12">
                          <div>
                              <div className="truck-contact-formdiv1">
                                  <form action="">
                                      <div className="">
                                          <label className="home-contact-text1">Full Name</label>
                                          <input
                                              type="text"
                                              name="fname"
                                              class="form-control home-input-field1"
                                          />
                                      </div>
                                      <div className=" home-input-div2">
                                          <label className="home-contact-text1">Email</label>
                                          <input
                                              type="email"
                                              name="email"
                                              class="form-control home-input-field1"
                                          />
                                      </div>
                                      <div className=" home-input-div2">
                                          <label className="home-contact-text1">Phone Number</label>
                                          <input
                                              type="number"
                                              name="phone"
                                              class="form-control home-input-field1"
                                          />
                                      </div>

                                      <div>
                                          <div class="form-group form-check truck-checkbox-div1">
                                              <input
                                                  type="checkbox"
                                                  class="form-check-input home-checkbox-div2"
                                                  id="exampleCheck1"
                                              />
                                              <label
                                                  class="form-check-label home-checkbox-text1"
                                                  for="exampleCheck1"
                                              >
                                                  I have read and agree to <span>Terms of Service</span>{" "}
                                              </label>
                                          </div>
                                          <div class="form-group form-check ">
                                              <input
                                                  type="checkbox"
                                                  class="form-check-input home-checkbox-div2"
                                                  id="exampleCheck1"
                                              />
                                              <label
                                                  class="form-check-label home-checkbox-text1"
                                                  for="exampleCheck1"
                                              >
                                                  I want to receive the most useful tips and news{" "}
                                              </label>
                                          </div>
                                      </div>

                                      <div className=" position-relative">
                                          <a href="#">
                                              <div className="truck-contact-us-btn1">
                                                  Send Request
                                              </div>
                                          </a>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



          <div className='container-fluid shippers-divs11'>
            <div className='container'>
                  <h1 className="truck-services-text4 d-flex ml-0">
                     Dispatch Solutions for Shippers and Brokers
                   
                  </h1>

                  <p className="truck-services-para11">
                      American Movers is known for its excellent freight dispatch service. Our team has provided dispatching services for owner-operators and trucking fleets since 2015. We help find their loads, communicate with their customers, and complete all necessary paperwork so they can focus on the important task of transporting goods.

                  </p>

                  <p className="truck-services-para11">
                      When you choose American Movers as your freight dispatch service, you enjoy clear pricing devoid of hidden payments and termination fees. You can rest assured that the price we quote you is the price you pay.

                  </p>

                  <p className="truck-services-para11">
                      Our team believes it takes both freight brokers and shippers to successfully transport loads. We can help make the process smoother for both parties. Here’s how.

                  </p>

                  <h1 className="truck-shippers-text4 d-flex">
                      How we can help freight brokers and shippers
                  </h1>

                  <p className="truck-services-para11">
                      One of the biggest advantages we provide freight brokers and shippers is our extensive pool of independent owner-operators eager to assist with their loads. Our drivers have all cleared our approval process and are ready to service your needs. Other ways American Movers can help include:

                  </p>

                  <p className="truck-services-para11">
                      ✔ <span>Eliminate double brokering.</span>  Double brokering is illegal unless the shipper consents to the arrangement. However, that does not stop some companies from engaging in the practice without the knowledge of the clients they represent. As independent dispatchers, we never double broker your loads. We work solely as independent dispatchers for drivers with whom we can provide all documentation. <br />
                      ✔ <span>Offer transparency.</span>  From our pricing model to updates about the progress of your load, we offer full transparency to freight brokers and carriers. Dispatchers keep track of preferred carriers and routes to ensure a smoother process each time you use our services. <br />
                      ✔ <span>Provide 24/7 updates.</span>  Our team never leaves freight brokers hanging. We provide timely updates on your loads during every leg of the journey. Potential delays or problems are communicated immediately. We are available 24/7 to track loads, answer your questions and concerns, and ensure your shipments arrive as planned. <br />
                  </p>

                  <p className="truck-services-para11">
                      Most freight brokers and shippers do not have the time required to locate a freight forwarder on their own. You can trust our team at American Movers with the most fragile cargo and the tightest deadlines. We find the best, most cost-efficient shipping solutions to meet your needs every time.

                  </p>

                  <h1 className="truck-shippers-text4 d-flex">
                      Our truck dispatcher service benefits
                  </h1>

                  <p className="truck-services-para11">
                      Working with American Movers ensures satisfaction for all parties involved in the shipping process. Whether you are a freight broker or a carrier, our team offers stress-free and on-time dispatching for your loads. Here is what you can expect when you choose our truck dispatcher services.

                  </p>

                  <div className='row truck-shippers-textdiv1'>
                      <div className='col-lg-6 col-12 truck-shippers-textdiv4'>
                          <h1 className="truck-shippers-text4 d-flex">
                              Benefits for Brokers
                          </h1>
                          <p className="truck-services-para11">
                               ✔ Access to a large pool of reputable carriers <br />
                               ✔ Financially flexible carriers to meet every budget <br />
                               ✔ Transparent pricing model with no hidden fees <br />
                               ✔ Reputable carriers with satisfactory performance records <br />
                               ✔ Regular updates on all loads and immediate communication for any transport issues or delays <br />
                               ✔ Follow no-solicitation guidelines
                          </p>
                      </div>

                      <div className='col-lg-6 col-12'>
                          <h1 className="truck-shippers-text4 d-flex truck-shippers-textdiv4">
                              Benefits for Carriers
                          </h1>
                          <p className="truck-services-para11">
                              ✔ Dedicated dispatchers to help match loads <br />
                              ✔ Rate confirmations are sent directly between broker and carrier <br />
                              ✔ No forced dispatch <br />
                              ✔ Advanced confirmation for all rates <br />
                               ✔ Dispatch managers are available to supervise all operations <br />
                              ✔ Access to a network of freight brokers in good standing
                          </p>
                      </div>
                  </div>


                  <h1 className="truck-shippers-text4 d-flex">
                      Why rely on American Movers
                  </h1>
                  <p className="truck-services-para11">
                      Shippers and brokers can eliminate the middleman when they work with American Movers. Our fleet of hundreds of trucks can move their loads on their timetables. We provide 24/7 support and updates, load tracking, and complete transparency in our operations. Our dispatchers communicate directly with carriers to save brokers time. Gain a competitive edge while eliminating the hassles that come with freight delivery when you choose our team. Contact us today to receive your no-obligation quote for service or to discuss options that meet your needs.

                  </p>
            </div>
          </div>


          <AmericanMovers></AmericanMovers>
          <Gross></Gross>
    </div>
  )
}

export default Shippers
