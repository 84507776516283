import React from "react";
import "./TruckDispatch.css";
import AmericanMovers from "./AmericanMovers";
import truckserviceimg1 from "../Images/home-trucks-img-5.jpg";
import truckserviceimg2 from "../Images/image 55.svg";
import Work from "./Work.js";
import Gross from "./Gross.js";
import { Link } from "react-router-dom";
import FAQ from "./FAQ.js";
import { questions } from "./FAQquest.js";
import { useState } from "react";


function Hotshot() {

  const [faqs, setfaqs] = useState(questions);

  return (
    <div className=" truck-main-div11122">
      <div className="container-fluid truck-div1">
        <div className="w-100 trucks-div-11">
          <div className="row">
            <div className="col-lg-6 col-12 d-flex justify-content-center">
              <div>
                <div>
                  <h1 className="truck-dispatch-text1">Hotshot</h1>
                </div>
                <div className="truck-dispatch-divtext1">
                  <div className="d-flex align-items-center">
                    <div className="truck-arrow-svg1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p className="truck-arrow-para1">
                      No forced dispatch and hidden payments
                    </p>
                  </div>

                  <div className="d-flex align-items-center mt-4">
                    <div className="truck-arrow-svg1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p className="truck-arrow-para1">
                      Personal dispatcher with 24 / 7 support
                    </p>
                  </div>

                  <div className="d-flex align-items-center mt-4">
                    <div className="truck-arrow-svg1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M0.606599 11.8781C0.18438 12.3003 0.18438 12.9849 0.606599 13.4071C1.02882 13.8293 1.71337 13.8293 2.13559 13.4071L0.606599 11.8781ZM13.9846 1.11022C13.9846 0.513112 13.5006 0.0290608 12.9035 0.0290619L3.17302 0.0290613C2.57592 0.0290612 2.09187 0.513113 2.09187 1.11022C2.09187 1.70733 2.57592 2.19138 3.17302 2.19138L11.8223 2.19138L11.8223 10.8406C11.8223 11.4378 12.3063 11.9218 12.9035 11.9218C13.5006 11.9218 13.9846 11.4378 13.9846 10.8406L13.9846 1.11022ZM2.13559 13.4071L13.6679 1.87471L12.139 0.345725L0.606599 11.8781L2.13559 13.4071Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <p className="truck-arrow-para1">From 7% of gross</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div>
                <div className="truck-contact-formdiv1">
                  <form action="">
                    <div className="">
                      <label className="home-contact-text1">Full Name</label>
                      <input
                        type="text"
                        name="fname"
                        class="form-control home-input-field1"
                      />
                    </div>
                    <div className=" home-input-div2">
                      <label className="home-contact-text1">Email</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control home-input-field1"
                      />
                    </div>
                    <div className=" home-input-div2">
                      <label className="home-contact-text1">Phone Number</label>
                      <input
                        type="number"
                        name="phone"
                        class="form-control home-input-field1"
                      />
                    </div>

                    <div>
                      <div class="form-group form-check truck-checkbox-div1">
                        <input
                          type="checkbox"
                          class="form-check-input home-checkbox-div2"
                          id="exampleCheck1"
                        />
                        <label
                          class="form-check-label home-checkbox-text1"
                          for="exampleCheck1"
                        >
                          I have read and agree to <span>Terms of Service</span>{" "}
                        </label>
                      </div>
                      <div class="form-group form-check ">
                        <input
                          type="checkbox"
                          class="form-check-input home-checkbox-div2"
                          id="exampleCheck1"
                        />
                        <label
                          class="form-check-label home-checkbox-text1"
                          for="exampleCheck1"
                        >
                          I want to receive the most useful tips and news{" "}
                        </label>
                      </div>
                    </div>

                    <div className=" position-relative">
                      <a href="#">
                        <div className="truck-contact-us-btn1">
                          Send Request
                        </div>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid truck-services-div1">
        <div className="container truck-services-truck-div1">
          <div className="row">
            <div className="col-lg-6 col12 d-flex align-items-center pr-5">
              <div>
                <h1 className="truck-services-text1">Hotshot Dispatch</h1>
                <p className="truck-services-text2">
                  Many people who are not in the trucking sector are unaware of the processes involved in delivering their supplies,
                  appliances, and equipment to their customers. Hotshot drivers like you are, in our opinion at American Movers LLC,
                  the "minutemen" of trucking, getting cargo where they need to go on schedule. For this reason, we provide excellent
                  hotshot dispatch services that have the potential to significantly impact your hotshot trucking company's
                  profitability.

                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div>
                <img
                  src={truckserviceimg1}
                  className="truck-services-img-div11 img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div>
            <div className="">
              <h1 className="truck-services-text4 d-flex ml-0 mt-5">
                Hotshot Loads for Dispatch
              </h1>

              <p className="truck-services-para11">
                As a business, hotshot trucking has its own overhead, charges, and costs. Ultimately, though, a large portion of your
                success will hinge on your capacity to haggle over prices and identify the most lucrative cargoes. The issue is that you
                could feel like you can't afford to spend more time looking for a job and bargaining on top of everything else you are
                doing. If your company is just getting started, you might not have the ideal negotiating position or expertise for this
                particular task. You will frequently discover that it is in your best interest to use dispatch services for hotshot
                trucking.

              </p>
              <p className="truck-services-para222">
                Managing Dispatch for Hotshots
              </p>

              <p className="truck-services-para11">
                The hotshot truck dispatching strategy used by American Movers LLC is straightforward: our goal is to increase our
                clients' profits. We accomplish this by looking for potential hotshot loads across a variety of platforms, some of
                which self-arranging individuals might not be able to access or recognize. Next, in an effort to increase loads'
                profitability, we try to negotiate the best pricing. Time is money, thus we also assist by sparing our clients the
                trouble and time it takes to handle this aspect of the business so they can focus on what they do best: providing the
                goods. We handle all the paperwork and more with our comprehensive transportation document management
                services, which we provide through our hotshot dispatch.
              </p>

              <p className="truck-services-para222">
                A Budget-Friendly Dispatch Solution for Hotshot Drivers
              </p>

              <p className="truck-services-para11">
                We can save small and medium-sized hotshot transportation companies a lot of money. We are frequently less
                expensive—even less expensive than it would be for one in-house dispatcher—than recruiting full-time, in-house
                dispatchers. Your business does not need to spend money on office space or invest time and resources in hiring
                qualified candidates. With our industry knowledge and access to helpful search tools for hotshot dispatch loads,
                American Movers LLC is an excellent choice for hotshot trucking companies looking to increase their profit line.
              </p>

              <p className="truck-services-para222">
                American movers LLC and additional expedited dispatch services
              </p>

              <p className="truck-services-para11">
                Have offered top-notch dispatch services across the trucking industry and the entire country. We have had
                numerous heart-to-hearts with our clients during that period. What we found out was that the following were the
                main concerns customers had when using a dispatch service for hotshot work instead of doing it all themselves:
              </p>

              <p className="truck-services-para11">
                ✔ <span> Forced dispatch </span> – This refers to a situation in which a truck driver is required to take any cargo that is presented
                to them and is not allowed to reject loads. We don't lock clients in this manner at American Movers LLC since
                we don't want anyone to feel that way.
              </p>

              <p className="truck-services-para11 mt-0">
                ✔ <span>Wasted time and money for little to no benefit </span> –
                For small and medium-sized enterprises, particularly those just getting started. They worry that the dispatch service won't be profitable in
                addition to the fact that they will have wasted money and time that could have been used for other aspects
                of the company. American Movers LLC will put in a lot of effort to obtain you more money because of our
                knowledge of the industry, commitment to our clients, and experience.

              </p>

              <p className="truck-services-para11 mt-0">
                ✔ <span>Exaggerated expectations: </span> – In an attempt to attract business owners, organizations occasionally exaggerate
                their capabilities and results for clients. Many hotshot truckers have unrealistic expectations, and when their
                business doesn't take off the way they may have been persuaded to assume, they end up disappointed or
                worse, turn away loads that may have been profitable. We at American Movers LLC Dispatch don't think sugarcoating is acceptable. We provide our clients with the facts so they may make reality-based decisions.
                reality.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid truck-dispatch-sec3-div1 pt-0">
        <div className="container">
          <div>
            <h1 className="truck-dispatch-sec3-text1">
              Choose The Service You Need
            </h1>
            <div className="row truck-dispatch-sec3-div2">
              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/truck-dispatch">
                  <div className="truck-dispatch-sec3-btn1">
                    Truck Dispatching
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/billing">
                  <div className="truck-dispatch-sec3-btn2">
                    {" "}
                    Billing & Accounting
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/factoring-service">
                  <div className="truck-dispatch-sec3-btn1">
                    Factoring Services
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <Link to="/document-management">
                  <div className="truck-dispatch-sec3-btn2">
                    Document Management
                  </div>
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <Link to="/safety&dot-compliance">
                  <div className="truck-dispatch-sec3-btn1">
                    SAFETY & DOT Compliance
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/rate-negotiation">
                  <div className="truck-dispatch-sec3-btn2">
                    Rate Negotiation
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/ifta-reporting">
                  <div className="truck-dispatch-sec3-btn1">IFTA Reporting</div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <Link to="/company-formation">
                  <div className="truck-dispatch-sec3-btn2">
                    Company Formation
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AmericanMovers></AmericanMovers>

      <div className="container-fluid faqs-div122" id="faq">
        <div className="container">
          <div>
            <h1 className="faq-text1">FAQ</h1>
          </div>
          <div className="faq-line1"></div>

          {faqs.map((faq) => {
            return (
              <FAQ
                question={faq.question}
                answer={faq.answer}
                key={faq.id}
              />
            );
          })}
        </div>
      </div>

      <Gross></Gross>
    </div>
  );
}

export default Hotshot;
