export const questions = [
    {
        id: 1,
        question: "What will American Movers LLC service do for my business?",

        answer: ` In short, we purchase your time to enable you to earn more money. A lot of owners and operators enter this
industry with the intention of driving loads and making money, but they soon discover that the paperwork, legal
regulations, and negotiations become a burden and prevent them from focusing on their core business. American
Movers LLC Dispatch makes your firm run smoothly so you may take on more loads by taking on all the business
support tasks you would like to outsource. Furthermore, our goal in negotiations is to secure the best prices for your
loads, allowing you to keep more of your money. `,
    },

    {
        id: 2,
        question:
            "Isn’t it always easier and cheaper to hire an in-house dispatcher? ",

        answer: ` Remarkably, no. It takes time and money, if head-hunters are required, to establish and implement a rigorous
recruitment process if you want to find talented and competent candidates. Even if you are successful in finding
what you are seeking for, you will probably still need to supply office space, which will cost extra money, and in
certain situations, further training. Hiring American Movers LLC Dispatch ends up being more affordable than even
having one full-time dispatcher, as numerous consumers have acknowledged. We are the best at what we do
because of our networks, experience, and industry knowledge. `,
    },
    {
        id: 3,
        question:
            "Do I have to be enter in to a long-term contract with American Movers LLC? ",

        answer: ` Not at all. American Movers LLC Dispatch is hired on a month-to-month basis. We believe in earning your business,
which is why we don't need trucker drivers to make long-term upfront commitments. We will work hard on your
behalf because if you aren't happy, neither are we. However, we also think that every business owner ought to be
allowed to collaborate with anybody they choose. It's critical that we preserve that autonomy for you. `,
    },
    {
        id: 5,
        question:
            "Do I have to be working under my own Mc authority to hire American Movers LLC?",

        answer: `We collaborate with carriers who use company drivers as well as owners/operators operating under their own MC
permissions.
`,
    },
    {
        id: 6,
        question: " Will you work with me if I’m new to the business?",

        answer: `  Certainly. We like supporting carriers and truck drivers at every step of their careers. We are looking for drivers who
are focused and diligent. Realistically, most shippers and brokers prefer carriers with MC authorities more than six
months to a year old, thus it could be more difficult for us to locate you the finest loads at first if you are a new
carrier. But don't worry, we at American Movers LLC Dispatch will work tirelessly to improve your earnings during
your first year. It will be even simpler to get better rates and loads after you reach the one-year mark`,
    },
    {
        id: 7,
        question: "Can i choose American Movers LLC services I want? ",

        answer: ` Sure. While many of our clients adore our all-inclusive service package, which covers dispatch, paperwork,
compliance, and all the business support a carrier requires, we also have clients who, for example, only need help
with paperwork. At American Movers LLC Dispatch, we think that our clients ought to be able to do business
however they see proper. Nobody knows their business like they do, after all.`,
    },
];
