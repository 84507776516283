import React from 'react'
import './callbutton.css'


function Callbtn() {
  return (
    <div>
          <div className='container-fluid position-relative'>
              <div className=''>
                  <a href="tel:+16403004266">
                      <div className='call-btn-div11'>
                          <div className='call-btn-div12'></div>
                          <div className='call-btn-div13'></div>
                          <div className='call-btn-div14'></div>
                          <div className='call-btn-div15'></div>
                          <div className='call-btn-div16'></div>
                      </div>
                </a>
                  
              </div>


              
          </div>
    </div>
  )
}

export default Callbtn
