import React from 'react'
import './Work.css'
import worktruckimg1 from '../Images/truck .jpg'
import worktruckimg2 from '../Images/home-trucks-img-2.png'
import worktruckimg3 from '../Images/home-trucks-img-3.svg'
import worktruckimg4 from '../Images/home-trucks-img-4-1.png'
import worktruckimg5 from '../Images/img-power-only-2.png'
import worktruckimg6 from '../Images/Cargo Van.jpg'
import worktruckimg7 from '../Images/boxtruck-article.png'
import worktruckimg8 from '../Images/straight-2.png'
import Fade from "react-reveal/Fade";
import { Link } from 'react-router-dom'



function Work() {
    return (
        <div>
            <div className='container-fluid home-work-div1' id='trucktypes'>
                <div className='container'>
                    <div>
                        <Fade bottom distance="0%">
                            <h1 className='home-work-text1'>Who Can</h1>
                            <h1 className='home-work-text2'>Work With Us</h1>
                        </Fade>
                        <div className='row home-work-carddiv001'>
                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/hotshot-truck ' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>01</p>
                                                    <h1 className='home-work-text4'>Hotshot </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg1} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/step-deck ' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>02</p>
                                                    <h1 className='home-work-text4'>Step Deck  </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg2} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/reefer ' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>03</p>
                                                    <h1 className='home-work-text4'>Reefer </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg3} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/flat-bed' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>04</p>
                                                    <h1 className='home-work-text4'>Flatbed </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg4} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/power-only ' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>05</p>
                                                    <h1 className='home-work-text4'>Power Only  </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg5} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/dry-van' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>06</p>
                                                    <h1 className='home-work-text4'>Dry Van </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg6} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/box-truck' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>07</p>
                                                    <h1 className='home-work-text4'>Boxtruck </h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg7} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>


                            <Fade bottom distance="3%">

                                <div className='col-lg-3 col-md-6 col-12 w-100'>
                                    <div className='home-work-carddiv1'>
                                        <Link to='/straight-truck' >
                                            <div className='d-flex align-items-center works-card-div001'>
                                                <div className=''>
                                                    <p className='home-work-text3'>08</p>
                                                    <h1 className='home-work-text4'>Straight Truck</h1>
                                                </div>
                                                <div className='position-relative d-flex justify-content-end'>
                                                    <div className='home-work-carddiv2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M0.605623 11.8776C0.183404 12.2998 0.183404 12.9844 0.605623 13.4066C1.02784 13.8288 1.71239 13.8288 2.13461 13.4066L0.605623 11.8776ZM13.9836 1.10973C13.9836 0.512624 13.4996 0.0285725 12.9025 0.0285736L3.17205 0.028573C2.57494 0.0285729 2.09089 0.512624 2.09089 1.10973C2.09089 1.70684 2.57494 2.19089 3.17205 2.19089L11.8213 2.19089L11.8213 10.8402C11.8213 11.4373 12.3054 11.9213 12.9025 11.9213C13.4996 11.9213 13.9836 11.4373 13.9836 10.8402L13.9836 1.10973ZM2.13461 13.4066L13.667 1.87423L12.138 0.345237L0.605623 11.8776L2.13461 13.4066Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className='home-work-carddiv3' >
                                            <img src={worktruckimg8} className='img-fluid' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work
