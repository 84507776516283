import React, { useState } from 'react'

function FAQ({ question, answer }) {

    const [show, setshow] = useState(false);
    return (
        <div>

            <div className="faq-main-div2">

                <div className="d-flex faq-div3" onClick={() => setshow(!show)} >
                    <h1 className="faq-text3">
                        {/* What will American Movers service do for my business? */}
                        {question}
                    </h1>
                    <div className="faq-svg-div1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path
                                d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>

                {show && <div className='faq-text44-div1'>
                    <p className='faq-text44'>{answer}</p>
                </div>
                }

                <div className="faq-line1"></div>

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      Isn’t it always eaesir and cheaper to hire an in-house
                      dispatcher?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      Do i have to be enter in to a long-term contract with Logiy
                      Dispatch?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      How do i sign up with you, and what do i need?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      Do i have to be working under my own Mc authority to hire
                      American Movers?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      Will you work with me if I’m new to the business?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}

                {/* <div className="d-flex faq-div3">
                  <h1 className="faq-text3">
                      Can i choose American Movers services I want?{" "}
                  </h1>
                  <div className="faq-svg-div1">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                      >
                          <path
                              d="M1.87731 0.962596C1.457 0.538476 0.772458 0.535387 0.348338 0.955696C-0.0757817 1.37601 -0.0788708 2.06055 0.341438 2.48467L1.87731 0.962596ZM14.7209 16.5446C15.318 16.5473 15.8042 16.0655 15.8069 15.4684L15.8508 5.73805C15.8535 5.14095 15.3717 4.65472 14.7746 4.65202C14.1775 4.64933 13.6912 5.13119 13.6886 5.72829L13.6495 14.3775L5.00034 14.3384C4.40324 14.3357 3.91701 14.8176 3.91432 15.4147C3.91162 16.0118 4.39349 16.498 4.99059 16.5007L14.7209 16.5446ZM0.341438 2.48467L13.9579 16.2245L15.4937 14.7025L1.87731 0.962596L0.341438 2.48467Z"
                              fill="white"
                          />
                      </svg>
                  </div>
              </div> */}

                {/* <div className="faq-line1"></div> */}
            </div>
        </div>
    )
}

export default FAQ
